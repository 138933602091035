import React from 'react'
import { Spring } from 'react-spring/renderprops'

import { VisibilitySensorOnce } from '../components/VisibilitySensorOnce'
import Seo from '../components/seo'
import { Layout } from '../components/layout'
import { HeroBanner } from '../components/HeroBanner'
import { Container } from '../components/Container'
import { UnderlinedHeader } from '../components/UnderlinedHeader'
import { ContactDataRow } from '../components/ContactDataRow'
import { Input } from '../components/Input'
import { Button } from '../components/Button'
import { Script } from '../components/Script'

import { withBreakpoints } from '../hoc/withBreakpoints'
import { googleMapStyles } from '../constants/googleMapStyles'

import * as styles from './kontakt-page.module.scss'
import NewTabLink from '../components/NewTabLink'
import { graphql } from 'gatsby'
import { normalizePhoneNumber, prettifyPhoneNumber } from '../utils'

const UnderlinedHeaderWithBreakpoints = withBreakpoints(
  {
    mobile: {
      underlinePlacement: 'bottom-left',
    },
    tablet: {
      underlinePlacement: 'bottom-left',
    },
    desktop: {
      underlinePlacement: 'bottom-center',
    },
    fallback: {
      underlinePlacement: 'bottom-left',
    },
  },
  UnderlinedHeader
)

const createMap = (selector, companyName) => {
  const position = {
    lat: 50.0677287,
    lng: 19.9426499,
  }
  /* eslint-disable */
  const map = new google.maps.Map(document.querySelector(selector), {
    center: position,
    zoom: 14,
    styles: googleMapStyles,
  })
  const infoTooltip = new google.maps.InfoWindow({
    content: companyName,
  })
  const marker = new google.maps.Marker({
    position,
    icon: '/assets/icons/pin-1.svg',
    content: infoTooltip,
  })

  marker.addListener('click', () => {
    infoTooltip.open({
      anchor: marker,
      map,
      shouldFocus: false,
    })
  })

  /* eslint-enable */
  marker.setMap(map)
}

const Contact = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <Layout darkHero>
    <Script
      src={`https://maps.googleapis.com/maps/api/js?key=${frontmatter.googleMapsApiKey}`}
      onLoad={() => createMap(`.${styles.contactPage__map}`, frontmatter.companyName)}
    />
    <Seo title="Kontakt" description={frontmatter.seoDescription} keywords={frontmatter.seoKeywords} />
    <HeroBanner
      header={
        <>
          <span>{frontmatter.title1}</span> <span>{frontmatter.title2}</span>
        </>
      }
    >
      {frontmatter.subtitle}
    </HeroBanner>
    <Container className={styles.contactPage__container}>
      <section className={styles.contactPage__contactDataSection}>
        <UnderlinedHeader theme="dark" underlinePlacement="bottom-left">
          Dane kontaktowe
        </UnderlinedHeader>
        <div className={styles.contactPage__contactData}>
          <div className={styles.contactPage__contactDataColumn}>
            <ContactDataRow icon="user" data={[frontmatter.companyName, frontmatter.companyOwnerFullName]} />
            <ContactDataRow
              icon="pin"
              data={[frontmatter.address.street, `${frontmatter.address.postalCode} ${frontmatter.address.city}`]}
            />
          </div>
          <div className={styles.contactPage__contactDataColumn}>
            <ContactDataRow
              icon="phone"
              data={frontmatter.phoneNumbers.map(({ phoneNumber }) => (
                <a className={styles.contactPage__link} href={`tel:${normalizePhoneNumber(phoneNumber)}`}>
                  {prettifyPhoneNumber(phoneNumber)}
                </a>
              ))}
            />
            <ContactDataRow
              icon="chat"
              data={[
                <a className={styles.contactPage__link} href={`mailto:${frontmatter.email}`}>
                  {frontmatter.email}
                </a>,
              ]}
            />
            {frontmatter.facebook.enabled && (
              <ContactDataRow
                icon="facebook"
                data={[
                  <NewTabLink className={styles.contactPage__link} href={frontmatter.facebook.fullUrl}>
                    {frontmatter.facebook.shortUrl}
                  </NewTabLink>,
                ]}
              />
            )}
            {frontmatter.instagram.enabled && (
              <ContactDataRow
                icon="instagram"
                data={[
                  <NewTabLink className={styles.contactPage__link} href={frontmatter.instagram.fullUrl}>
                    {frontmatter.instagram.shortUrl}
                  </NewTabLink>,
                ]}
              />
            )}
            {frontmatter.pinterest.enabled && (
              <ContactDataRow
                icon="pinterest"
                data={[
                  <NewTabLink className={styles.contactPage__link} href={frontmatter.pinterest.fullUrl}>
                    {frontmatter.pinterest.shortUrl}
                  </NewTabLink>,
                ]}
              />
            )}
          </div>
        </div>
      </section>
      <section className={styles.contactPage__contactFormSection}>
        <UnderlinedHeader theme="dark" underlinePlacement="bottom-left">
          Formularz kontaktowy
        </UnderlinedHeader>
        <VisibilitySensorOnce partialVisibility minTopValue={100}>
          {({ isVisible }) => (
            <Spring to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <form
                  id="contact-form"
                  style={{ opacity }}
                  className={styles.contactPage__contactForm}
                  name="contact"
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                  onSubmit={(e) => {
                    e.preventDefault()
                    const contactForm = document.getElementById('contact-form')
                    const contactFormData = new FormData(contactForm)
                    fetch('/', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                      body: new URLSearchParams(contactFormData).toString(),
                    })
                      .then((response) => {
                        if (response.status >= 200 && response.status <= 399) {
                          alert('Przyjęliśmy zgłoszenie. Dziękujemy!')
                        } else {
                          throw new Error(`Status ${response.status}`)
                        }
                      })
                      .catch(() => {
                        alert(
                          'Nie udało się wysłać wiadomości. Przepraszamy i uprzejmie prosimy o informację mailową lub telefoniczną.'
                        )
                      })
                  }}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <Input
                    name="contactPhoneNumber"
                    type="text"
                    placeholder="Telefon"
                    className={styles.contactPage__contactFormInput}
                  />
                  <Input
                    name="contactEmail"
                    type="text"
                    placeholder="Adres e-mail"
                    className={styles.contactPage__contactFormInput}
                  />
                  <textarea
                    name="contactMessage"
                    className={styles.contactPage__contactFormTextArea}
                    placeholder="Wiadomość"
                  />
                  <div data-netlify-recaptcha="true" />
                  <Button type="submit">Wyślij wiadomość</Button>
                </form>
              )}
            </Spring>
          )}
        </VisibilitySensorOnce>
      </section>
    </Container>
    <section className={styles.contactPage__mapSection}>
      <Container>
        <UnderlinedHeaderWithBreakpoints theme="dark" className={styles.contactPage__mapHeader}>
          Mapa dojazdu
        </UnderlinedHeaderWithBreakpoints>
      </Container>
      <div className={styles.contactPage__mapWrapper}>
        <div className={styles.contactPage__map} />
      </div>
    </section>
  </Layout>
)

export default Contact

export const kontaktPageQuery = graphql`
  query KontaktPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoDescription
        seoKeywords
        title1
        title2
        subtitle
        companyName
        companyOwnerFullName
        email
        googleMapsApiKey
        address {
          street
          city
          postalCode
        }
        phoneNumbers {
          phoneNumber
        }
        facebook {
          enabled
          fullUrl
          shortUrl
        }
        instagram {
          enabled
          fullUrl
          shortUrl
        }
        pinterest {
          enabled
          fullUrl
          shortUrl
        }
      }
    }
  }
`
