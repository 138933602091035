import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import * as styles from './Input.module.scss'

const Input = ({ type, placeholder, name, className }) => (
  <input className={cx([styles.input, className])} type={type || 'text'} placeholder={placeholder} name={name} />
)

Input.propTypes = {
  // Add other types if needed
  type: PropTypes.oneOf(['text']),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
}

export { Input }
