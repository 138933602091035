import React from 'react'
import cx from 'clsx'

import * as styles from './User.module.scss'

export const User = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.user, primary && styles.userPrimary, className])}
    viewBox="0 0 595.256 595.282"
    {...props}
    fill="currentColor"
  >
    <path d="M289.5 286.8c39.4 0 73.5-14.1 101.4-42 27.9-27.9 42-62 42-101.4 0-39.4-14.1-73.5-42-101.4C363 14.1 328.9 0 289.5 0c-39.4 0-73.5 14.1-101.4 42s-42 62-42 101.4c0 39.4 14.1 73.5 42 101.4C216 272.6 250.1 286.8 289.5 286.8zM212.8 66.7c21.4-21.4 46.5-31.8 76.7-31.8 30.2 0 55.3 10.4 76.7 31.8 21.4 21.4 31.8 46.5 31.8 76.7 0 30.2-10.4 55.3-31.8 76.7 -21.4 21.4-46.5 31.8-76.7 31.8 -30.2 0-55.3-10.4-76.7-31.8 -21.4-21.4-31.8-46.5-31.8-76.7C181 113.2 191.4 88.1 212.8 66.7z" />
    <path d="M540.4 457.7c-0.8-11.6-2.4-24.3-4.8-37.6 -2.4-13.5-5.5-26.2-9.3-37.8 -3.9-12-9.1-23.9-15.6-35.3 -6.7-11.8-14.6-22.1-23.4-30.6 -9.3-8.9-20.6-16-33.7-21.2 -13.1-5.2-27.5-7.8-43-7.8 -6.1 0-12 2.5-23.3 9.9 -7 4.6-15.2 9.8-24.3 15.7 -7.8 5-18.4 9.6-31.4 13.8 -12.7 4.1-25.7 6.2-38.4 6.2 -12.8 0-25.7-2.1-38.4-6.2 -13-4.2-23.6-8.9-31.4-13.8 -9-5.8-17.2-11-24.3-15.7 -11.3-7.4-17.2-9.9-23.3-9.9 -15.5 0-29.9 2.6-43 7.8 -13.1 5.2-24.4 12.3-33.7 21.2 -8.8 8.5-16.7 18.7-23.4 30.5 -6.5 11.4-11.7 23.2-15.5 35.3 -3.7 11.6-6.8 24.4-9.2 37.8 -2.4 13.3-4 26-4.8 37.6 -0.8 11.4-1.2 23.2-1.2 35.2 0 31.1 9.9 56.2 29.4 74.8 19.2 18.3 44.7 27.6 75.6 27.6h286.6c31 0 56.4-9.3 75.6-27.6 19.5-18.5 29.4-43.7 29.4-74.8C541.6 480.9 541.1 469.1 540.4 457.7zM488.1 542.4c-12.7 12.1-29.6 18-51.6 18H149.9c-22 0-38.9-5.9-51.6-18 -12.5-11.9-18.5-28.1-18.5-49.5 0-11.2 0.4-22.2 1.1-32.7 0.7-10.4 2.2-21.8 4.4-33.9 2.1-12 4.9-23.2 8.1-33.3 3.1-9.7 7.4-19.4 12.7-28.7 5-8.9 10.8-16.5 17.2-22.6 6-5.7 13.5-10.4 22.4-13.9 8.2-3.3 17.4-5 27.5-5.3 1.2 0.7 3.4 1.9 6.9 4.2 7.2 4.7 15.4 10 24.6 15.8 10.3 6.6 23.6 12.5 39.4 17.6 16.2 5.2 32.7 7.9 49.1 7.9 16.4 0 32.9-2.7 49.1-7.9 15.9-5.1 29.1-11.1 39.4-17.6 9.4-6 17.4-11.2 24.6-15.8 3.5-2.3 5.7-3.5 6.9-4.2 10 0.3 19.3 2 27.5 5.3 8.9 3.5 16.4 8.2 22.4 13.9 6.4 6.1 12.2 13.7 17.2 22.6 5.3 9.3 9.5 18.9 12.7 28.7 3.3 10.2 6 21.4 8.1 33.3 2.2 12.1 3.6 23.5 4.4 33.9v0c0.7 10.5 1.1 21.5 1.1 32.7C506.7 514.4 500.6 530.6 488.1 542.4z" />
  </svg>
)
