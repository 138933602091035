import React from 'react'
import cx from 'clsx'

import * as styles from './Chat.module.scss'

export const Chat = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.chat, primary && styles.chatPrimary, className])}
    viewBox="0 0 595.255 595.282"
    fill="currentColor"
    {...props}
  >
    <path d="M542.9 184.1H420.9V96.9c0-28.8-23.5-52.3-52.3-52.3H52.3c-28.8 0-52.3 23.5-52.3 52.3v209.3c0 28.9 23.5 52.3 52.3 52.3h17.4v52.3c0 14 15.6 22.2 27.1 14.5l77.5-51.7v72.1c0 28.9 23.5 52.3 52.3 52.3h171.4l100.2 66.8c11.5 7.7 27.1-0.5 27.1-14.5v-52.3h17.4c28.9 0 52.3-23.5 52.3-52.3V236.5C595.3 207.6 571.8 184.1 542.9 184.1zM182.1 326.6c0 0 0 0 0 0l-77.4 51.6v-37.2c0-9.6-7.8-17.4-17.4-17.4h-34.9c-9.6 0-17.4-7.8-17.4-17.4V96.9c0-9.6 7.8-17.4 17.4-17.4H368.6c9.6 0 17.4 7.8 17.4 17.4v209.3c0 9.6-7.8 17.4-17.4 17.4H191.8C188.5 323.7 185 324.7 182.1 326.6zM560.4 445.7c0 9.6-7.8 17.4-17.4 17.4h-34.9c-9.6 0-17.4 7.8-17.4 17.4v37.2l-77.5-51.7c-2.9-1.9-6.2-2.9-9.7-2.9H226.7c-9.6 0-17.4-7.8-17.4-17.4v-87.2H368.6c28.8 0 52.3-23.5 52.3-52.3v-87.2h122.1c9.6 0 17.4 7.8 17.4 17.4V445.7z" />
    <path d="M226.7 219H87.2c-9.6 0-17.4 7.8-17.4 17.4 0 9.6 7.8 17.4 17.4 17.4h139.5c9.6 0 17.4-7.8 17.4-17.4C244.1 226.8 236.3 219 226.7 219z" />
    <path d="M333.7 149.3H87.2c-9.6 0-17.4 7.8-17.4 17.4s7.8 17.4 17.4 17.4H333.7c9.6 0 17.4-7.8 17.4-17.4S343.3 149.3 333.7 149.3z" />
  </svg>
)
