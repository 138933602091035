import React from 'react'
import cx from 'clsx'

import * as styles from './Phone.module.scss'

export const Phone = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.phone, primary && styles.phonePrimary, className])}
    viewBox="0 0 595.255 595.282"
    {...props}
  >
    <path
      d="M580 439.1l-74-74c-20.4-20.4-53.6-20.4-74 0l-33.6 33.6c-15.5 15.5-40.6 15.5-56.1 0L196.5 252.8c-15.5-15.5-15.5-40.6 0-56.1 5.4-5.4 21-21 33.6-33.6 20.3-20.3 20.6-53.4 0-74l-74-73.7c-20.4-20.4-53.6-20.4-73.9-0.1 -15.2 15.1-20.1 20-27 26.7 -73.6 73.6-73.6 193.3 0 266.9L286.2 540c73.7 73.7 193.1 73.8 266.9 0l26.9-26.9C600.4 492.7 600.4 459.5 580 439.1zM106.8 40c6.8-6.8 17.9-6.8 24.7 0l74 73.8c6.8 6.8 6.8 17.8 0 24.7l-12.3 12.3L94.5 52.1 106.8 40zM310.9 515.3L79.8 284.2c-56.9-56.9-60-146.3-9.6-207l98.3 98.3c-25.8 29.3-24.8 73.9 3.2 101.9l145.8 145.9c0 0 0 0 0 0 28 28 72.6 29.1 101.9 3.2l98.3 98.3C457.4 575.3 368.2 572.6 310.9 515.3zM555.3 488.4l-12.3 12.3 -98.7-98.7 12.3-12.3c6.8-6.8 17.9-6.8 24.7 0l74 74C562.1 470.5 562.1 481.6 555.3 488.4z"
      fill="currentColor"
    />
  </svg>
)
