import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { Spring } from 'react-spring/renderprops'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import { User as IconUser } from '../Icons/User'
import { Phone as IconPhone } from '../Icons/Phone'
import { Chat as IconChat } from '../Icons/Chat'
import IconFacebook from '../Icons/Social/Facebook'
import IconInstagram from '../Icons/Social/Instagram'
import { getPinIcon } from '../Icons/Pin'

import * as styles from './ContactDataRow.module.scss'
import PinterestIcon from '../Icons/Social/Pinterest'

const getIcon = (iconName) => {
  switch (iconName) {
    case 'user':
      return IconUser
    case 'pin':
      return getPinIcon('type-2')
    case 'phone':
      return IconPhone
    case 'chat':
      return IconChat
    case 'facebook':
      return () => (
        <IconFacebook className={cx([styles.contactDataRow__icon, styles.contactDataRow__iconFacebook])} hollow />
      )
    case 'instagram':
      return () => <IconInstagram className={cx([styles.contactDataRow__icon, styles.contactDataRow__iconInstagram])} />
    case 'pinterest':
      return () => <PinterestIcon className={cx([styles.contactDataRow__icon, styles.contactDataRow__iconInstagram])} />
    default:
      return null
  }
}

const ContactDataRow = ({ icon, data }) => {
  const Icon = getIcon(icon)
  return (
    <VisibilitySensorOnce>
      {({ isVisible }) => (
        <Spring to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : 20 }}>
          {({ opacity, translate }) => (
            <div style={{ opacity }} className={styles.contactDataRow}>
              <div className={styles.contactDataRow__iconWrapper}>
                <Icon primary />
              </div>
              <div style={{ transform: `translateX(${translate}px)` }} className={styles.contactDataRow__content}>
                {data.map((entity, i) => (
                  <p className={styles.contactDataRow__contentParagraph} key={i}>
                    {entity}
                  </p>
                ))}
              </div>
            </div>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
  )
}

ContactDataRow.propTypes = {
  icon: PropTypes.oneOf(['user', 'pin', 'phone', 'chat', 'facebook', 'instagram', 'pinterest']),
  data: PropTypes.arrayOf(PropTypes.node),
}

export { ContactDataRow }
