// extracted by mini-css-extract-plugin
export var contactPage__container = "kontakt-page-module--contact-page__container--2SKnm";
export var contactPage__contactData = "kontakt-page-module--contact-page__contact-data--2aHF5";
export var contactPage__contactDataSection = "kontakt-page-module--contact-page__contact-data-section--1KCSG";
export var contactPage__contactDataColumn = "kontakt-page-module--contact-page__contact-data-column--1rzZZ";
export var contactPage__contactFormSection = "kontakt-page-module--contact-page__contact-form-section--Yj-w2";
export var contactPage__contactFormInput = "kontakt-page-module--contact-page__contact-form-input--2sDKM";
export var contactPage__contactFormTextArea = "kontakt-page-module--contact-page__contact-form-text-area--3olNP";
export var contactPage__link = "kontakt-page-module--contact-page__link--3JEZi";
export var contactPage__map = "kontakt-page-module--contact-page__map--MZvhG";
export var contactPage__mapHeader = "kontakt-page-module--contact-page__map-header--14yuS";
export var contactPage__mapSection = "kontakt-page-module--contact-page__map-section--3DJH6";
export var contactPage__mapWrapper = "kontakt-page-module--contact-page__map-wrapper--3UDN0";