import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Script = React.memo(({ src, onLoad, removeOnUnmount }) => {
  useEffect(() => {
    const head = document.head
    const script = document.createElement('script')
    head.appendChild(script)
    script.src = src
    script.onload = onLoad

    if (removeOnUnmount) {
      return () => {
        head.removeChild(script)
      }
    }
  })

  return null
})

Script.propTypes = {
  src: PropTypes.string,
  onLoad: PropTypes.func,
  removeOnUnmount: PropTypes.bool,
}

export { Script }
